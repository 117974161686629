import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Funkcja do odczytania nonce z nagłówków CSP
function getNonce() {
  const cspMeta = document.querySelector(
    'meta[http-equiv="Content-Security-Policy"]'
  );
  if (cspMeta) {
    const match = cspMeta.content.match(/'nonce-([\w\d]+)'/);
    return match ? match[1] : null;
  }
  return null;
}

const nonce = getNonce();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App nonce={nonce} />
  </React.StrictMode>
);
